/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('RegOutputController',['$scope', '$rootScope', '$state', 'HeaderService', function ($scope, $rootScope, $state, HeaderService) {

        //region General

        HeaderService.onChangeState($scope, function(message) {
            if(_.contains(['annexa.reg.output.entries', 'annexa.reg.output.notifications', 'annexa.reg.output.remittances'], message.state.name)) {
                $rootScope.headButtons = [];
                $rootScope.subHeadButtons = [ new HeadButtonSearch('#tableFilter') ];
				$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#dataTableOutput', 'fa fa-download', 'global.literals.export', undefined, 'exportList'));
                    
                $rootScope.subHeadTabs = [
                    { state:'annexa.reg.output.entries', type:'breadDivButton', name:'global.literals.outputs', permissions: ['view_output_register', 'view_all_output_register'] }
                ];
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality && $rootScope.app.configuration.show_notification_functionality.value){
                    $rootScope.subHeadTabs.push({ state:'annexa.reg.output.notifications', type:'breadDivButton active', name:'global.literals.notifications', permissions: ['view_notifications', 'view_all_notifications'] });
                }
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_remittances_functionality && $rootScope.app.configuration.show_remittances_functionality.value){
                    $rootScope.subHeadTabs.push({ state:'annexa.reg.output.remittances', type:'breadDivButton', name:'global.literals.remittances', permissions: ['view_remittance', 'view_all_remittance'] });
                }
            }

            if(message.state.name == 'annexa.reg.output.entries') {
                $rootScope.headButtons = [ new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#dataTableOutput','fa-plus', 'global.reg.output.new', undefined, 'newOutput').setPermissions('new_output_entry_register') ];
                if ($rootScope.app.configuration.show_register_entry_sync_doc_buttons && $rootScope.app.configuration.show_register_entry_sync_doc_buttons.value) {
                	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#dataTableInput','fa-refresh', 'global.reg.literals.allDocumentsSync', undefined, 'allDocumentsSync').setPermissions('edit_output_entry_register'));
                }
                $rootScope.subHeadTabs[0].type = 'breadDivButton active';
                if($rootScope.subHeadTabs[1]){
                    $rootScope.subHeadTabs[1].type = 'breadDivButton';
                }
            } else if(message.state.name == 'annexa.reg.output.remittances') {
            	$rootScope.headButtons = [ new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#dataTableOutput','fa-plus', 'global.remittance.literals.newRemittance', undefined, 'newRemittance').setPermissions(['manage_remittance', 'manage_all_remittance']) ];
            	$rootScope.headButtons.push( new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#dataTableOutput','fa-check-square', 'global.remittance.literals.processRemittances', undefined, 'processRemittances').setPermissions(['manage_all_remittance']) );
            	$rootScope.headButtons.push( new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#dataTableOutput','fa-refresh', 'global.remittance.literals.updateStatus', undefined, 'updateStatus').setPermissions(['manage_all_remittance']) );
            	$rootScope.subHeadTabs[0].type = 'breadDivButton';
            	if($rootScope.subHeadTabs.length == 2) {
            		$rootScope.subHeadTabs[1].type = 'breadDivButton active';
            	} else if($rootScope.subHeadTabs.length == 3) {
            		$rootScope.subHeadTabs[1].type = 'breadDivButton';
            		$rootScope.subHeadTabs[2].type = 'breadDivButton active';
            	}
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegOutputEntriesController',['$scope', '$rootScope', '$filter', '$state', 'RegFactory', 'ErrorFactory', 'RouteStateFactory', 'TableFilterFactory', 'GlobalDataFactory', 'Language', 'DialogsFactory', 'HelperService', 'RegModals', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'RestService', '$stateParams', 'AnnexaPermissionsFactory', function($scope, $rootScope, $filter, $state, RegFactory, ErrorFactory, RouteStateFactory, TableFilterFactory, GlobalDataFactory, Language, DialogsFactory, HelperService, RegModals, AnnexaFormlyFactory, AnnexaModalFactory, RestService,$stateParams, AnnexaPermissionsFactory) {

        //region Filter
    	
    	// ORDENAR EL LLISTAT DE REGISTRE //
        $scope.tableOrderProperties = { sortName: 'registerDate', sort: [[0,'desc']] };


        var managed = [
            { id: true, name: 'global.reg.literals.managed' },
            { id: false, name: 'global.reg.literals.noManaged' }
        ]

        $scope.filter = [
            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.numberOuput', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','entryNumber'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','entryNumber') },
            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','procedureName'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','procedureName') },
            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','dossierNumber'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','dossierNumber') },
            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsOutput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','registerEntryChannel',GlobalDataFactory.allRegisterEntryChannelsOutput, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','registerEntryChannel') },
            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','extract'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','extract') },
            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','dataReg'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','dataReg') },
            { id: 'entryDocumentType', type: 'select', order: 7, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesOutput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','entryDocumentType',GlobalDataFactory.registerEntryDocumentTypesOutput, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','entryDocumentType') },
            { id: 'entryClassification', type: 'select', order: 8, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsOutput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','entryClassification',GlobalDataFactory.registerEntryClassificationsOutput, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','entryClassification') },
            { id: 'representant', type: 'text', order: 9, label: 'global.literals.destiny', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','representant'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','representant') },
            { id: 'managed', type: 'select', order: 10, label: 'global.literals.state', dataType: 'LOCAL', data: managed, addAll: true, nameProperty: 'name', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','managed',managed, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','managed') },
            { id: 'observations', type: 'text', order: 11, label: 'global.literals.observations', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','observations'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','observations') },
            { id: 'profile', type: 'select', order: 12, label: 'global.literals.profile', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.allProfiles,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','profile',GlobalDataFactory.allProfiles, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','tableRegOutputEntries')  },
            { id: 'originEntryNumber', type: 'text', order: 13, label: 'global.literals.originEntryInputNumber', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','originEntryNumber'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','originEntryNumber') },
            { id: 'originRegisterOrgan', type: 'text', order: 14, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','originRegisterOrgan') },
            { id: 'withoutDocuments', type: 'checkbox', order: 15, label: 'global.literals.withoutDocuments', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','withoutDocuments'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','withoutDocuments')},
            { id: 'section', type: 'select-tree', order: 16, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'address', type: 'text', order: 17, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','address'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','address') },
            { id: 'createUser', type: 'text', order: 18, label: 'global.reg.literals.createUserReg', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','createUser'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','createUser') }
        ];
        
        if($rootScope.app.configuration.tram_classification_register_output.show) {
        	$scope.filter.push({ id: 'tramClassification', type: 'select', order: 19, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableRegOutputEntries', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableRegOutputEntries', 'tramClassification')})
        }

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            $scope.filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','registerEntryOffice', GlobalDataFactory.registerEntryOffices, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','registerEntryOffice') });
        }
        $scope.filter.push(
                { id: 'historical', type: 'checkbox', order: 20, label: 'global.literals.showhistorical', callAux: true, preFilter:false, negated:false}
        );

		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
		        var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
		}
        //endregion

        //region Table

        var getFilterCall = function () {
            var filterCall = TableFilterFactory.getFilterCall($scope.filter);

            filterCall.entryType = 'OUTPUT';

            if(filterCall && filterCall.managed && (filterCall.managed.id || filterCall.managed.id === false)) {
                filterCall.managed = filterCall.managed.id;
            }else {
            	var manag = $linq($scope.filter).firstOrDefault(undefined, "x => x.id == 'managed'");
            	if(manag && manag.model && (manag.model.id || manag.model.id === false)){
            		filterCall.managed = manag.model.id;
            	}
            }

            return filterCall;
        }

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);

            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = Language.getActiveColumn();

            return filterCallAux;
        }

        var entryNumberRender = function(data, type, full, meta) {
            var content = '';
            if(full && full.annulled) {
                content += '<i class="fa fa-times-circle text-danger" title="'+((full.annulledDate)?$filter('date')(full.annulledDate, 'dd/MM/yyyy'):'')+' - '+((full.annulledReason)?full.annulledReason:'')+'"></i>';
            }
            content = content + " "+ data;
            return content;
        }
        
        var columns = [
            { id: 'entryNumber', width: '110px', title: $filter('translate')('global.literals.numberOuput'), render: entryNumberRender },
            { id: 'registerDate', width: '110px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
            { id: 'registerEntryChannel.'+ Language.getActiveColumn(), width: '60px', title: $filter('translate')('global.literals.channel') },
            { id: 'extract', title: $filter('translate')('global.literals.extract'), className: 'pre-line', sortable: false },
            { id: 'thirds', width: '200px', column: new PrincipalThirdColumn($filter,'global.literals.principalDestiny'), sortable: false },
            { id: 'createUser', column: new UserColumn($filter, 'global.reg.literals.createUserReg') },
            { id: 'actions', columnName: 'id', width: '100px', className: 'text-center', sortable: false, column: new ActionsColumn(
                    $filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','annexa.reg.output.entries.edit({ output: \'[data]\'})','fa-eye', true),
                    [ new ActionButton('global.reg.literals.generateEvidenceDocument','createSuportingDocument([data])','', false),
                      new ActionButton('global.reg.copyRegister.copyRegisterEntry','copyRegisterEntry([data])','fa-files-o', false),
                      new ActionButton('global.reg.literals.registerDocumentSync','registerDocumentSync([data])','fa-refresh', false, function(data, type, full, meta){
                      	if ($rootScope.app.configuration.show_register_entry_sync_doc_buttons && $rootScope.app.configuration.show_register_entry_sync_doc_buttons.value &&
                      			full && full.registerEntryChannel && full.registerEntryChannel.code == 'TELOUT') {
                      		return true;
                      	} else {
                      		return false;
                      	}
          			})
                    ,
                    new ActionButton('global.reg.literals.undoAnnullateRegister','undoAnnullateRegister([data])','fa-refresh', false, function(data, type, full, meta){
	                  	if (AnnexaPermissionsFactory.havePermission("annullate_registers") && full && full.annulled) {
	                  		return true;
	                  	} else {
	                  		return false;
	                  	}
      			    }),
                    new ActionButton('global.reg.literals.annullateRegister','annullateRegister([data])','fa-refresh', false, function(data, type, full, meta){
	                  	if (AnnexaPermissionsFactory.havePermission("annullate_registers") && full && !full.annulled) {
	                  		return true;
	                  	} else {
	                  		return false;
	                  	}
	      			})]
               )}
        ]

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.unshift({ id: 'registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            $scope.tableOrderProperties.sort.index += 1;
        }

        var datatableSettings = $rootScope.app.configuration.datatables_settings;

        columns = getDatatableColumnsSettings(datatableSettings, 'datatable_register_entry_output', columns);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_register_entry_output', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = { sortName: orderProperties.name, sort: [[orderProperties.index, orderProperties.direction]] };
        }

        $scope.tableDefinition = {
            id: 'tableRegOutputEntries',
            origin: 'reg',
            objectType: 'RegisterEntry',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy(columns),
            containerScope: $scope,
            useTableObjects: true,
			notAddExcel: true
        }

        //endregion

        //region Mètodes

        $scope.newOutput = function(){
            $state.transitionTo('annexa.reg.output.entries.new');
        };

        $scope.allDocumentsSync = function(){
        	RegFactory.allDocumentsSync().then(function (data) {
        		if (data) {
        			DialogsFactory.notify('global.reg.literals.syncDocumentsWarning', $filter('translate')('global.reg.literals.syncDone'));
        		} else {
        			DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'syncDoc', { message: 'Fail sync'}), $filter('translate')('DIALOGS_ERROR_MSG'));
        		}
        	}).catch(function (error) {
        		DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'syncDoc', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
        	});
        };

        $scope.registerDocumentSync = function(id){
        	RegFactory.registerDocumentSync(id).then(function (data) {
        		if (data && data.response) {
        			DialogsFactory.notify(data.response, $filter('translate')('global.reg.literals.syncDone'));
        		}
        	}).catch(function (error) {
        		DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'syncDoc', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
        	});
        }

        $scope.annullateRegister = function(id){
        	var reloadStateOrReloadTable = function(){
    			$scope.tableDefinition.reloadInternalData(false,true);
        	};
        	
        	RegFactory.annullateRegister(id, true, reloadStateOrReloadTable);
        }
        
        $scope.undoAnnullateRegister = function(id){
        	var reloadStateOrReloadTable = function(){
    			$scope.tableDefinition.reloadInternalData(false,true);
        	};
        	
        	RegFactory.annullateRegister(id, false, reloadStateOrReloadTable);
        }
        $scope.createSuportingDocument = function(id) {
            $rootScope.loading(true);

            RegFactory.createSupportingDocument(id, 'OUTPUT', 'FILE')
                .then(function(data) {
                    if(window.navigator.msSaveOrOpenBlob) {
                        try {
                            window.navigator.msSaveOrOpenBlob(data, "pdf.pdf");
                        } catch(e) {
                            console.error(e.message);
                        }
                    } else {
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        data.dispatchEvent(clickEvent);
                    }
                    $rootScope.loading(false);
                }).catch(function(error) {
                    $rootScope.loading(false);
					var errorAux = ((error.data)?error.data:undefined);
					if(errorAux){
						errorAux = String.fromCharCode.apply(null, new Uint8Array(errorAux));
						if(errorAux){
							errorAux = angular.fromJson(errorAux);
						}else{
							errorAux = undefined;
						}
					}else{
						errorAux = undefined;
					}
					if(errorAux && errorAux.exception && errorAux.exception.toUpperCase().endsWith('XDocReportException'.toUpperCase())){
						var msg = $filter('translate')('global.tram.errors.processTemplate')+((errorAux.message)?'<br/><br/>'+errorAux.message:'');
						DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
					}else{
			        	DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'evidencedocument', error && error.data ? error.data : ''));
					}
            });
        }
		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

        $scope.copyRegisterEntry = function(id){
            var submitCopyRegisterEntry = function($modal) {
                if($modal.annexaFormly.form.$valid) {
                    var data = $modal.annexaFormly.model.modal_body;

                    var model = {
                        numCopies: data.numCopies,
                        copyInterested: data.copyInterested_1 || false,
                        copyDocuments: data.copyDocuments_1 || false,
                        copyDiligences: data.copyDiligences_1 || false,
                        copyNotifications: (data.copyInterested_1 && data.copyDocuments_1) ? (data.copyNotifications_1 || false) : false,
                        copyOther: data.copyOther_1 || false,
                        copyUserAndProfile: data.copyUserAndProfile_1 || false,
                        copyPresentationDate: data.copyPresentationDate_1 || false
                    };
                    
                    RegFactory.copyRegisterEntry(id, model).then(function (data) {
//                    	modal.close();
                    }).catch(function (error) {
                        console.error(error);
                    });

                    $modal.close();

                    var modal = angular.copy(RegModals.copyRegisterEntryResult);
                    AnnexaModalFactory.showModal('modalCopyRegisterEntryResult', modal);
                }
            };

        	var modal = RegModals.copyRegisterEntry;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false };
            modal.annexaFormly.model.modal_body = { 
            		numCopies: 1,
            		copyInterested_1: false,
            		copyDocuments_1: false,
            		copyDiligences_1: false,
            		copyNotifications_1: true,
            		copyOther_1: false,
            		entryType: 'OUTPUT',
            		copyUserAndProfile_1: false,
            		copyPresentationDate_1: false
            };

            AnnexaFormlyFactory.showModal("modalCopyRegisterEntry", modal, submitCopyRegisterEntry, false);
        }

        //endregion

    }])
    .controller('RegOutputNotificationsController',['$scope', '$rootScope', '$filter', '$state', 'RouteStateFactory', 'TableFilterFactory', 'GlobalDataFactory', 'Language', 'NotificationFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory','HelperService', 'RestService', '$stateParams', function($scope, $rootScope, $filter, $state, RouteStateFactory, TableFilterFactory, GlobalDataFactory, Language, NotificationFactory, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory,HelperService,RestService,$stateParams) {

        //region General
    	$scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
        $scope.alerts = [];
        $scope.notificationStates = angular.copy(NotificationFactory.notificationGlobalStatus);
        $scope.notificationStates.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        
        //endregion

        //region Filter

     // ORDENAR LES NOTIFICACIONS //
        $scope.tableOrderProperties = { sortName: 'createdDate', sort: [[3,'desc']] };
        
        $scope.filter = [
            { id: 'extract', type: 'text', order: 1, label: 'global.literals.extract', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','extract'), negated:HelperService.getPrefilterNegated('register','tableNots','extract')},
            { id: 'notificationType', type: 'select', order: 2, label: 'global.literals.notificationTypes', dataType: 'LOCAL', data: GlobalDataFactory.notificationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','notificationType',GlobalDataFactory.notificationTypes,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','notificationType')},
            { id: 'globalStatus', type: 'select', order: 3, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: NotificationFactory.notificationGlobalStatus, nameProperty: 'name', addAll: true, callAux: true, preFilter:(($stateParams.status)?$stateParams.status:HelperService.getPrefilter('register','tableNots','globalStatus',NotificationFactory.notificationGlobalStatus,'id')), negated:(($stateParams.status)?false:HelperService.getPrefilterNegated('register','tableNots','globalStatus'))},
            { id: 'status', type: 'select', order: 4, label: 'global.literals.particularStatus', dataType: 'LOCAL', data: GlobalDataFactory.notificationStatuses, nameProperty: Language.getActiveColumn(), addAll: true, callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','status',GlobalDataFactory.notificationStatuses,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','status')},
            { id: 'entryNumber', type: 'text', order: 5, label: 'global.reg.datatable.numberOutput', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','entryNumber'), negated:HelperService.getPrefilterNegated('register','tableNots','entryNumber')},
            { id: 'dossierNumber', type: 'text', order: 6, label: 'global.literals.numExp', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','dossierNumber'), negated:HelperService.getPrefilterNegated('register','tableNots','dossierNumber')},
            { id: 'thirdName', type: 'text', order: 7, label: 'global.literals.receivers', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','thirdName'), negated:HelperService.getPrefilterNegated('register','tableNots','thirdName')},
            { id: 'userCreator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','userCreator'), negated:HelperService.getPrefilterNegated('register','tableNots','userCreator')},
            { id: 'createdDate', type: 'dateRange', order: 9, label: 'global.literals.creation_date', callAux:true, preFilter:HelperService.getPrefilter('register','tableNots','createdDate'), negated:HelperService.getPrefilterNegated('register','tableNots','createdDate')},
            { id: 'originEntryNumber', type: 'text', order: 10, label: 'global.literals.originEntryInputNumber', callAux:true, preFilter:HelperService.getPrefilter('register','tableNots','originEntryNumber'), negated:HelperService.getPrefilterNegated('register','tableNots','originEntryNumber') },
            { id: 'originRegisterOrgan', type: 'text', order: 11, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableNots','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableNots','originRegisterOrgan') }
        ];
        
        if($rootScope.app.configuration.tram_classification_register_output.show) {
        	$scope.filter.push({ id: 'tramClassification', type: 'select', order: 11, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableNots', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableNots', 'tramClassification')})
        }

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            $scope.filter.splice(3, 0, { id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','registerEntryOffice',GlobalDataFactory.registerEntryOffices,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','registerEntryOffice') });                                      
        }
		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
		    var routeState = RouteStateFactory.getRouteState($state.current);
	
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
		}
        //endregion

        //region Table

        $scope.legend = [];

        var getFilterCall = function () {
            var filterCall = TableFilterFactory.getFilterCall($scope.filter);

            return filterCall;
        };

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = Language.getActiveColumn();
            if($scope.changeStatusFromHeader){
        		if($scope.filterData.state){
        			filterCallAux.globalStatus = {id: $scope.filterData.state};
        		}else{
        			filterCallAux.globalStatus = undefined;
        		}
        		
        		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'globalStatus'");
        		if(filterAux){
        			var status = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
        			if(status){
                        filterAux.model = status;
        			}else{
        			    filterAux.model = {};
        			}
        		}
        		$scope.changeStatusFromHeader = false;
        	}else if(filterCallAux.globalStatus && filterCallAux.globalStatus.id != $scope.filterData.state){
        		$scope.filterData.state = filterCallAux.globalStatus.id;
        	}else if(!filterCallAux.state){
        		$scope.filterData.state = undefined;
        	}
            return filterCallAux;
        };

        var renderNotificationLastStatus = function (data, type, full, meta) {
            var orderedByDateStatuses = $linq(data).orderBy("x => x.createdDate").toArray();

            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
        };
        
        

        $scope.notificationGlobalStatus = NotificationFactory.notificationGlobalStatus;
        
        $scope.headerIconNotificationGlobalStatus = function(icon) {
            if($scope.notificationStates && $scope.notificationStates.length > 0 && $scope.filterData.state) {
                var status = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }
            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        var notificationGlobalStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconNotificationGlobalStatus(true)}} {{headerIconNotificationGlobalStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in notificationStates | orderBy:\'orderStatus\'" ng-click="setNotificationGlobalTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setNotificationGlobalTypeDT = function(state){
            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#tableNots').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }

        var notificationGlobalStatusRender = function(data, type, full, meta) {
            var content = '';

            if($scope.notificationStates && $scope.notificationStates.length > 0) {
                var state = $linq($scope.notificationStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }
        
        var renderDossierNumberLink = function (data, type, full, meta) {
        	var content = '';
        	if(full.notification.dossier != null) {
        		if(full.notification.dossier.dossierStatus != 'ARCHIVED' && full.notification.dossier.dossierStatus != 'REJECTED' && full.notification.dossier.dossierStatus != 'TRANSFER'){
	        		if(full.notification.dossier.dossierStatus != 'FINISHED' && AnnexaPermissionsFactory.havePermission('process_dossier')) {
	        			
	        			var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
	                    var dossierProcessProfiles = [];
	                    var dossierTransaction = null;
	                    if(full.notification.dossier.dossierTransactions) {
	                        _.forEach(full.notification.dossier.dossierTransactions, function (dt) {
	                            if (dt.actual && dt.processProfiles) {
	                                _.forEach(dt.processProfiles, function (item) {
	                                    dossierProcessProfiles.push(item.profile.id);
	                                })
	                                var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();
	                                if (intersect.length > 0) {
	                                    dossierTransaction = dt;
	                                }
	                            }
	                        });
	                    }
	
	                    if(dossierTransaction) {
	                        content = '<a class="text-primary" href="" ng-click="openDossier('+data+','+dossierTransaction.id+',\'annexa.tram.pending.viewdossier\','+full.notification.dossier.bigDossier+')">' + full.notification.dossier.dossierNumber + '</a>';
	                    }else{
	                    	content = '<a class="text-primary" href="" ng-click="openDossier('+data+',undefined,\'annexa.tram.view.viewdossier\','+full.notification.dossier.bigDossier+')">' + full.notification.dossier.dossierNumber + '</a>';
	                     }
	            	} else {
	            		content = '<a class="text-primary" href="" ng-click="openDossier('+data+',undefined,\'annexa.tram.view.viewdossier\','+full.notification.dossier.bigDossier+')">' + full.notification.dossier.dossierNumber + '</a>';
	            	}
        		}else{
        			content = '<span>' + full.notification.dossier.dossierNumber + '</span>';
        		}
        	}
        	return content;
        }
       
       
        var columns = [
            { id: 'notification.globalStatus', width: '20px',  title: notificationGlobalStatusTitle(), render: notificationGlobalStatusRender, sortable: false },
            { id: 'notification.notificationType.'+ Language.getActiveColumn(), width: '150px', column: new DatabaseTranslatedColumn($filter, 'global.literals.notificationTypes', Language.getActiveColumn()) },
            { id: 'createdDate', width: '120px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
            { id: 'statuses', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
            { id: 'statuses', width: '150px', column: new LastStatusUpdate($filter, 'global.literals.lastStatusUpdate')},
            { id: 'notification.extract', width: '120px', title: $filter('translate')('global.literals.extract'), className: 'pre-line'},
            { id: 'notification.registerEntry.id', width: '120px', column: new UIRouterStateColumn($filter, 'global.reg.datatable.numberOutput','annexa.reg.output.entries.edit({output:\'[data]\'})', undefined, 'var number = full.notification.registerEntry.entryNumber; number;')},
            { id: 'notification.dossier.id', width: '120px', title: $filter('translate')('global.literals.dossierNumber'), render: renderDossierNumberLink},
            { id: 'third', column: new ThirdColumn($filter, 'global.literals.receivers')},
            { id: 'notification.createUser', column: new UserColumn($filter, 'global.literals.user') },
            { id: 'actions', columnName: 'id', width: '100px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','showNotificationModal([data])','fa-eye', false), []), sortable: false}
        ]
        
        
        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.splice(6, 0, { id: 'notification.registerEntry.registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            if ($scope.tableOrderProperties.sort.index >= 5) {
            	$scope.tableOrderProperties.sort.index += 1;
            }
        }
        
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        columns = getDatatableColumnsSettings(datatableSettings, 'datatable_register_entry_output_notifications', columns);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_register_entry_output_notifications', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableNots',
            origin: 'reg',
            objectType: 'NotificationThird',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy(columns),
            containerScope: $scope,
            useTableObjects: true,
			notAddExcel: true
        };

        //endregion

        // region Mètodes

        $scope.showNotificationModal = function (notificationId) {
            var notification = $scope.tableObjects[notificationId].notification;

            var notificationViewModal = NotificationFactory.notificationViewModal(notification);

            AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
        };

        
        $scope.openDossier = function (id, idDT, url, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	if(url == 'annexa.tram.view.viewdossier'){
	            		window.open($state.href(url, { dossier: id }));
	            	}else{
	            		window.open($state.href(url, { dossier: id, dossierTransaction:idDT }));
	            	}
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		if(url == 'annexa.tram.view.viewdossier'){
        			window.open($state.href(url, {dossier: id }), '_blank');
        		}else{
        			window.open($state.href(url, { dossier: id, dossierTransaction:idDT }));
        		}
        	}
        }

        
        $scope.$on('reloadNotificationTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(true, true);
        });

		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

        // endregion

    }])
    .controller('RegOutputRemittancesController',['$scope', '$rootScope', '$filter', '$state', 'RouteStateFactory', 'TableFilterFactory', 'GlobalDataFactory', 'Language', 'RemittanceFactory', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory','HelperService', 'RestService', '$stateParams', 'NotificationFactory', function($scope, $rootScope, $filter, $state, RouteStateFactory, TableFilterFactory, GlobalDataFactory, Language, RemittanceFactory, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory,HelperService,RestService,$stateParams, NotificationFactory) {

        //region General
    	$scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
        $scope.alerts = [];
        $scope.remittanceStates = angular.copy(RemittanceFactory.remittanceGlobalStatus);
        $scope.remittanceStates.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        
        $scope.newRemittance = function() {
            $state.transitionTo('annexa.reg.output.remittances.new');
        };
        
        $scope.updateStatus = function() {
            DialogsFactory.confirm('global.remittance.literals.updateStatus', 'global.remittance.literals.updateStatusMsg')
	            .then(function (data) {
					RemittanceFactory.deleteNotification(notificationId).then(function(data) {
						
	                }).catch(function (error) {
	                	console.error(error);
	                });
	            }).catch(function(data) {
	            //Empty
	        });
        }
        
        $scope.processRemittances = function() {
            DialogsFactory.confirm('global.remittance.literals.processRemittances', 'global.remittance.literals.processRemittancesMsg')
	            .then(function (data) {
					RemittanceFactory.processRemittances().then(function(data) {
        				DialogsFactory.notify($filter('translate')('global.remittance.literals.processRemittancesResult') + " " + data);
        				$scope.tableDefinition.reloadInternalData(true, true);
	                }).catch(function (error) {
	                	DialogsFactory.error(error.data.message);
	                });
	            }).catch(function(data) {
	            //Empty
	        });
        }
        
        $scope.updateStatus = function() {
            DialogsFactory.confirm('global.remittance.literals.updateStatus', 'global.remittance.literals.updateStatusMsg')
	            .then(function (data) {
					RemittanceFactory.updateStatus().then(function(data) {
        				$scope.tableDefinition.reloadInternalData(true, true);
	                }).catch(function (error) {
	                	DialogsFactory.error(error.data.message);
	                });
	            }).catch(function(data) {
	            //Empty
	        });
        }
        
        var showEnableAction = function (data, type, full, meta) {
            if(full.status && full.status != 'OPEN'){
                return false;
            } else {
                return true;
            }
        }
        
        //endregion

        //region Filter

        // ORDENAR LES REMESES //
        $scope.tableOrderProperties = { sortName: 'createdDate', sort: [[3,'desc']] };
        
        $scope.filter = [
        	{ id: 'status', type: 'select', order: 1, label: 'global.remittance.literals.status', dataType: 'LOCAL', data: RemittanceFactory.remittanceGlobalStatus, nameProperty: 'name', addAll: true, callAux: true, preFilter:HelperService.getPrefilter('register','tableRemittances','status',RemittanceFactory.remittanceGlobalStatus,'id'), negated:HelperService.getPrefilterNegated('register','tableRemittances','status')},
        	{ id: 'remittanceNumber', type: 'text', order: 2, label: 'global.remittance.literals.number', callAux: true, preFilter:HelperService.getPrefilter('register','tableRemittances','remittanceNumber'), negated:HelperService.getPrefilterNegated('register','tableRemittances','remittanceNumber')},
        	{ id: 'notificationsCount', type: 'text', order: 3, label: 'global.remittance.literals.notificationsCount', callAux: true, preFilter:HelperService.getPrefilter('register','tableRemittances','notificationsCount'), negated:HelperService.getPrefilterNegated('register','tableRemittances','notificationsCount')},
        	{ id: 'createdDate', type: 'dateRange', order: 4, label: 'global.remittance.literals.creation_date', callAux:true, preFilter:HelperService.getPrefilter('register','tableRemittances','createdDate'), negated:HelperService.getPrefilterNegated('register','tableRemittances','createdDate')},
        	{ id: 'closeDate', type: 'dateRange', order: 5, label: 'global.remittance.literals.closeDate', callAux:true, preFilter:HelperService.getPrefilter('register','tableRemittances','closeDate'), negated:HelperService.getPrefilterNegated('register','tableRemittances','closeDate')},
        	{ id: 'endDate', type: 'dateRange', order: 6, label: 'global.remittance.literals.endDate', callAux:true, preFilter:HelperService.getPrefilter('register','tableRemittances','endDate'), negated:HelperService.getPrefilterNegated('register','tableRemittances','endDate')}
        ];
        
		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $0scope.filter);
			}
		}else{
		    var routeState = RouteStateFactory.getRouteState($state.current);
	
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
		}
        //endregion

        //region Table

        $scope.legend = [];

        var getFilterCall = function () {
            var filterCall = TableFilterFactory.getFilterCall($scope.filter);

            return filterCall;
        };

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);
            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = Language.getActiveColumn();
            return filterCallAux;
        };

        $scope.remittanceGlobalStatus = RemittanceFactory.remittanceGlobalStatus;
        
        $scope.headerIconRemittanceStatus = function(icon) {
            if($scope.remittanceStates && $scope.remittanceStates.length > 0 && 
            		$scope.tableDefinition.filterCallAux.status && $scope.tableDefinition.filterCallAux.status.id) {
                var status = $linq($scope.remittanceStates).singleOrDefault(undefined, "x => x.id == '" + $scope.tableDefinition.filterCallAux.status.id + "'");
                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }
            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        var remittanceStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconRemittanceStatus(true)}} {{headerIconRemittanceStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in remittanceStates" ng-click="setRemittanceTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setRemittanceTypeDT = function(state){
        	$scope.filter[0].model = state;
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#tableNots').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }

        var remittanceStatusRender = function(data, type, full, meta) {
            var content = '';

            if($scope.remittanceStates && $scope.remittanceStates.length > 0) {
                var state = $linq($scope.remittanceStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }
        
        var columns = [
            { id: 'status', width: '20px',  title: remittanceStatusTitle(), render: remittanceStatusRender, sortable: false },
            { id: 'remittanceNumber', width: '120px', title: $filter('translate')('global.remittance.literals.number')},
            { id: 'notificationsCount', width: '20px', title: $filter('translate')('global.remittance.literals.notificationsCount')},
            { id: 'createdDate', width: '120px', column: new DateTimeColumn($filter, 'global.remittance.literals.creation_date')},
            { id: 'closeDate', width: '120px', column: new DateTimeColumn($filter, 'global.remittance.literals.closeDate')},
            { id: 'endDate', width: '120px', column: new DateTimeColumn($filter, 'global.remittance.literals.endDate')},
            { id: 'actions', columnName: 'id', width: '100px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'),
            		new ActionButton('global.literals.see','annexa.reg.output.remittances.edit({ remittance: \'[data]\'})','fa-eye', true),
                    [ new ActionButton('global.literals.remove','deleteRemittance([data])','', false, showEnableAction)]), sortable: false}
        ];
        
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        columns = getDatatableColumnsSettings(datatableSettings, 'datatable_register_entry_output_notifications', columns);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_register_entry_output_notifications', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }
        
        $scope.deleteRemittance = function(id) {
            DialogsFactory.confirm('global.literals.delete', 'global.remittance.literals.deleteRemittance')
                .then(function (data) {
					RemittanceFactory.deleteRemittance(id).then(function(data) {
						if($scope.tableDefinition){ 
			            	$scope.changeStatusFromHeader = true;
			            	$scope.tableDefinition.reloadInternalData(true, true);
			            }else{
			                var scopeAux = angular.element('#tableRemittances').scope();
			                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
			                    scopeAux.tableDefinition.reloadInternalData(true, true);
			                }
			            }
                    }).catch(function (error) {
                    	console.error(error);
                    });
                }).catch(function(data) {
                //Empty
            });
       
        }

        $scope.tableDefinition = {
            id: 'tableRemittances',
            origin: 'reg',
            objectType: 'Remittance',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy(columns),
            containerScope: $scope,
            useTableObjects: true,
			notAddExcel: true
        };

        //endregion

        // region Mètodes

        $scope.showNotificationModal = function (notificationId) {
            var notification = $scope.tableObjects[notificationId].notification;

            var notificationViewModal = NotificationFactory.notificationViewModal(notification);

            AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);
        };

        
        $scope.openDossier = function (id, idDT, url, bigDossier) {
        	if(bigDossier){
	        	DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm')
	            .then(function (data) {
	            	if(url == 'annexa.tram.view.viewdossier'){
	            		window.open($state.href(url, { dossier: id }));
	            	}else{
	            		window.open($state.href(url, { dossier: id, dossierTransaction:idDT }));
	            	}
	           }).catch(function (data) {
		               //Empty
		       });
        	}else{
        		if(url == 'annexa.tram.view.viewdossier'){
        			window.open($state.href(url, {dossier: id }), '_blank');
        		}else{
        			window.open($state.href(url, { dossier: id, dossierTransaction:idDT }));
        		}
        	}
        }

        
        $scope.$on('reloadNotificationTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(true, true);
        });

		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}

        // endregion

    }])
    .controller('RegOutputRemittanceNewController',['$scope', '$rootScope', 'HeaderService', '$state', '$stateParams', function($scope, $rootScope, HeaderService, $state, $stateParams) {

        //region General

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.output.remittances.new') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-remittance','fa-floppy-o','global.literals.save',undefined,'saveRemittance'),
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-remittance',undefined,'global.literals.cancel',undefined,'cancelRemittance')
                ];
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegOutputEntriesNewController',['$scope', '$rootScope', 'HeaderService', '$state', '$stateParams', function($scope, $rootScope, HeaderService, $state, $stateParams) {

        //region General

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.output.entries.new') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-registerentry','fa-floppy-o','global.literals.save',undefined,'saveRegistry'),
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-registerentry',undefined,'global.literals.cancel',undefined,'cancelRegistry')
                ];
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegOutputEntriesEditController',['$scope', '$rootScope', 'HeaderService', '$state', 'RegFactory', function($scope, $rootScope, HeaderService, $state, RegFactory) {

        //region General

        $scope.registerEntry = angular.copy(RegFactory.regEntry);

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.output.entries.edit' || message.state.name == 'annexa.reg.viewOutput') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#edit-registerentry',undefined,'global.reg.literals.generateEvidenceDocument',undefined,'createSuportingDocument'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditRegisterEntry').setPermissions('admin_audit'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-files-o', 'global.reg.copyRegister.copyRegisterEntry', undefined, 'copyRegisterEntry')
                ];
                if ($rootScope.app.configuration.show_sicres_button && $rootScope.app.configuration.show_sicres_button.value) {
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', undefined, 'global.reg.literals.generateDocumentSicres', undefined, 'generateDocumentSicres').setPermissions('generate_document_interoperable'));
                }
                if ($rootScope.app.configuration.show_register_entry_sync_doc_buttons && $rootScope.app.configuration.show_register_entry_sync_doc_buttons.value && 
                		RegFactory.regEntry && RegFactory.regEntry.registerEntryChannel && RegFactory.regEntry.registerEntryChannel.code == 'TELOUT') {
        			$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-refresh', 'global.reg.input.registerDocumentSync', undefined, 'registerDocumentSync').setPermissions('edit_output_entry_register'));
                }
                if(RegFactory.regEntry.annulled){
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-times-circle', 'global.reg.literals.undoAnnullateRegister', undefined, 'undoAnnullateRegister').setPermissions('annullate_registers'));
                }else{
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-times-circle', 'global.reg.literals.annullateRegister', undefined, 'annullateRegister').setPermissions('annullate_registers'));
                }
            }
        });

        HeaderService.changeState($state.current);

        //endregion
        
    }])
    .controller('RegOutputRemittanceEditController',['$scope', '$rootScope', 'HeaderService', '$state', 'RegFactory', function($scope, $rootScope, HeaderService, $state, RegFactory) {

        //region General

        $scope.remittance = angular.copy(RegFactory.remittance);

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.output.remittances.edit') {
            	$rootScope.subHeadButtons = [];
                if($scope.remittance && $scope.remittance.status == 'OPEN') {
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.closeRemittance', undefined, 'closeRemittance').setPermissions(['manage_all_remittance','manage_remittance']));
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.printRemittance', undefined, 'printRemittanceDoc').setPermissions(['manage_all_remittance','manage_remittance']));
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.printNotificationsListDocument', undefined, 'printNotificationsListDocument').setPermissions(['manage_all_remittance','manage_remittance']));
                }else if($scope.remittance && $scope.remittance.status == 'CLOSE') {
            		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.endRemittance', undefined, 'endRemittance').setPermissions(['manage_all_remittance','manage_remittance']));
            		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.createElectronicFile', undefined, 'createElectronicFile').setPermissions(['manage_all_remittance','manage_remittance']));
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.printRemittance', undefined, 'printRemittanceDoc').setPermissions(['manage_all_remittance','manage_remittance']));
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-remittance', undefined, 'global.remittance.literals.printNotificationsListDocument', undefined, 'printNotificationsListDocument').setPermissions(['manage_all_remittance','manage_remittance']));
                }
            }
        });

        HeaderService.changeState($state.current);

        //endregion
        
    }]);
